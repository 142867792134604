@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Open+Sans:400,600,700&display=swap');

html * {
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  background-color: #1f1f1f;
  font-size: 1rem;
  line-height: 100%;
  color: rgb(51, 51, 51);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
