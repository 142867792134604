.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
  height: 3rem;
  padding: 0 1.5rem;
  border-radius: 1.5rem;
  border-width: 1.5px;
  border-style: solid;
  border-color: transparent;
  outline: none;
  background-color: #eff0f0;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
}

.react-datepicker__input-container input {
  appearance: none;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  color: rgba(51, 51, 51);
}
